body {
  color: #ffffff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* standardize font sizes */
p {
  font-size: 22px;
  line-height: 30px;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 26px;
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 22px;
  }
  p {
    font-size: 18px;
    line-height: 26px;
  }
}
